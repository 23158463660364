import NavBar from "@/components/NavBar";
import constants from "@/constants";
import globalModel from "@/models/global.model";
import systemModel from "@/models/system.model";
import { useReactive, useRequest } from "ahooks";
import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";
import { useCopyToClipboard } from "react-use";
import { Button, Image, Toast } from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";

export default () => {
  const nav = useNavigate();
  const state = useReactive({
    loading: false,
  });
  const { user } = useSnapshot(globalModel.state);
  const [_, copy] = useCopyToClipboard();

  const url = `${window.location.protocol}//${
    window.location.host
  }/?code=${encodeURIComponent(user?.code)}`;

  const domToImage = async () => {
    return new Promise((r) => {
      setTimeout(() => {
        const node: any = document.getElementById("node");
        html2canvas(node, {
          allowTaint: true,
          useCORS: true,
        }).then(function (canvas) {
          const imgUrl = canvas.toDataURL();
          const addElement = document.createElement("a");
          addElement.href = imgUrl;
          addElement.download = `${constants.name}-邀请海报.png`;
          document.body.appendChild(addElement);
          addElement.click();
          document.body.removeChild(addElement);
        });
        r(true);
      }, 300);
    });
  };

  const { data } = useRequest(() => systemModel.getAvatar());

  return (
    <div className={styles.page}>
      <NavBar
        theme="none"
        title={<span style={{ color: "#fff" }}>邀请好友</span>}
        rightText={
          <a
            onClick={() => nav("/invite/detail/list")}
            style={{ color: "#fff" }}
          >
            邀请记录
          </a>
        }
      />
      <div id="node" className={styles.page}>
        <div className={styles.title}>邀请好友加入{constants.name}</div>
        <div className={styles.subtitle}>邀请双方均有机会获得稀有IP</div>
        <div className={styles.card}>
          {data?.data && <Image className={styles.avatar} src={data?.data} />}
          <div className={styles.user}>{user?.nickname}</div>
          <div className={styles.tips}>
            Hey，朋友！我在{constants.name}，一起来玩呀
          </div>
          {!!url && (
            <div className={styles.qrCode}>
              <QRCodeSVG
                className={styles.code}
                value={url}
                fgColor="#000000"
              />
            </div>
          )}
        </div>
        <div
          className={styles.info}
          onClick={() => {
            copy(user?.code);
            Toast({
              message: "复制成功",
              type: "success",
            });
          }}
        >
          <Image className={styles.icon} src="/images/invite/invite.png" />
          <div className={styles.right}>
            <div className={styles.code}>{user?.code}</div>
            <div className={styles.copy}>复制邀请码</div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            type="primary"
            block
            style={{ background: "#494D49" }}
            disabled={state.loading}
            onClick={async () => {
              state.loading = true;
              const update = Toast({
                type: "loading",
                message: "正在生成...",
                forbidClick: true,
                duration: 0,
              });
              try {
                await domToImage();
                Toast("海报已生成");
              } finally {
                update.clear();
                state.loading = false;
              }
            }}
          >
            生成海报
          </Button>
          <Button
            type="info"
            block
            style={{ color: "#252624" }}
            onClick={() => {
              copy(url);
              Toast("复制成功");
            }}
          >
            复制链接
          </Button>
        </div>
      </div>
    </div>
  );
};
